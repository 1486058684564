<template>
  <a-layout class="home">
    <div class="content" :class="{ mini: isMobile }">
      <div class="container">
        <a-row type="flex" justify="center" :gutter="[16, 16]">
          <a-col :xl="16" :lg="18">
            <img v-if="isMobile" :src="banner" alt="banner" class="logo-sm" />
            <h1>{{ labels.home.title }}</h1>
            <p>
              {{ labels.home.subtitle }}
              <b :style="{ display: 'block' }">{{ labels.appName }}</b>
            </p>
            <router-link
              :to="authenticated ? { name: 'buy' } : { name: 'login' }"
              class="link-start"
            >
              <a-button type="default" class="btn-start" size="large">{{
                authenticated ? labels.home.playNow : labels.home.startNow
              }}</a-button>
            </router-link>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="content-base container">
      <HowToPlay :authenticated="authenticated" />
    </div>
    <div class="content-base container results-container">
      <ResultList :show-description="!!$route?.params?.lottery" />
    </div>
    <div class="content-base container">
      <JoinOurMailList />
    </div>
  </a-layout>
</template>

<script>
import { mapState } from "vuex";
import HowToPlay from "@/components/HowToPlay.vue";
import JoinOurMailList from "@/components/JoinOurMailList.vue";
import loadingMixin from "@/mixins/loadingMixin";
import { pageTitle } from "../../utils/utils";
import labels from "@/utils/labels";
import banner from "@/assets/images/admin/banner.png";
import ResultList from "@/components/ResultList.vue";
import { mapActions } from "vuex/dist/vuex.common.js";
export default {
  name: "Home",
  components: { HowToPlay, JoinOurMailList, ResultList },
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.home.title,
      },
    ],
  },
  beforeRouteEnter(to, _from, next) {
    if (to.params?.uuid && to.name === "shorturl") {
      if (to.params.uuid.length !== 13) {
        next({ name: "home", replace: true });
      } else {
        // call method to get the short url
        next((vm) => vm.handleGetShortUrl(to.params.uuid));
      }
    } else {
      next();
    }
  },
  data() {
    return {
      labels: labels,
      banner: banner,
      submitting: false,
    };
  },
  computed: {
    ...mapState("clientAuth", ["authenticated"]),
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth <= 767;
    },
    indicator() {
      return <a-icon type="loading" style="font-size: 36px" spin />;
    },
  },
  methods: {
    ...mapActions("shortUrl", ["getShortUrl"]),
    async handleGetShortUrl(uuid) {
      let modal = null;
      try {
        this.submitting = true;
        // show redirecting message
        modal = this.$info({
          class: "redirecting-modal",
          keyboard: false,
          title: this.labels.home.redirecting,
          content: () => (
            <div class={"flex-content"}>
              <a-spin size="large" indicator={this.indicator} />
              <p>{this.labels.home.redirectingMessage}</p>
            </div>
          ),
          centered: true,
          closable: false,
          mask: true,
          okButtonProps: { style: { display: "none" } },
          maskClosable: false,
        });

        // call the api to get the short url
        const response = await this.getShortUrl(uuid);
        if (response?.data?.url) {
          // redirect to the short url
          window.location.href = response.data.url;
        } else {
          throw new Error("Invalid short url");
        }
      } catch (error) {
        console.error(error);
        if (modal) modal.destroy();
        this.$message.open({
          icon: <a-icon type="close-circle" style="color: #ff4d4f" />,
          key: "redirectingError",
          content: this.labels.home.redirectingError,
          duration: 5,
          onClose: () => {
            this.$router.push({ name: "home", replace: true });
          },
        });
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.home {
  max-width: 100% !important;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  min-height: calc(100vh - 43px);
  .content {
    width: 100%;
    max-height: calc(100vh - 43px);
    background-image: url("~@/assets/images/client/home-bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
    position: relative;
    z-index: 1;
    &.mini {
      padding-top: 2rem;
    }
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.55;
      z-index: -1;
      background-color: #20204e;
    }
    .logo-sm {
      width: 100%;
      max-width: 320px;
      margin-bottom: 9.5rem;
    }
    h1 {
      font-family: "Exo 2", sans-serif;
      color: color(--white);
      font-size: 3.5rem;
      font-weight: 700;
      margin: 0;
      line-height: 1.3;
      word-break: break-word;
      @media (max-width: 991px) {
        font-size: 3rem;
      }
    }
    p {
      margin: 0;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      font-size: 1rem;
      margin-top: 1rem !important;
      line-height: 1.7;
    }
    .btn-start {
      margin-top: 2rem;
      background-color: color(c-hover);
      color: color(c-primary);
      border-color: color(c-hover);
      border-radius: 50px;
      padding: 10px 30px;
      height: 50px;
    }
    &-base {
      background-image: none;
    }
  }
  .results-container {
    margin-bottom: 5rem;
  }
}
</style>
<style lang="scss">
@import "~@/assets/scss/variable";
.redirecting-modal {
  .ant-modal-content {
    background-color: color(c-secondary) !important;
    box-shadow: 0 1px 3px 3px color(c-hover-wait);
    .ant-modal-confirm-title {
      color: color(--white) !important;
    }
    .ant-modal-confirm-content {
      color: color(--white) !important;
      .flex-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 1rem;
        p {
          color: color(--white);
          margin-top: 1rem;
        }
        .anticon {
          color: color(c-hover);
        }
      }
    }
  }
}
</style>

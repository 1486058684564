var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"result-list"},[_c('h2',{staticClass:"result-list-title"},[_c('label',{attrs:{"for":"date-picker"}},[_vm._v(" "+_vm._s(_vm.labels.resultsOf)+": ")]),_c('a-date-picker',{attrs:{"placeholder":_vm.dateFormat,"inputReadOnly":"","disabledDate":_vm.disabledDate,"format":_vm.dateFormat,"mode":"date","defaultValue":_vm.$moment(),"allowClear":false,"htmlFor":"date-picker"},on:{"change":_vm.onChange},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('span',{staticClass:"date-field"},[_c('font-awesome-icon',{staticClass:"anticon",attrs:{"icon":['far', 'calendar-days']}}),_vm._v(" "+_vm._s(_vm.date ? _vm.$moment(_vm.date).format(_vm.dateFormat) : _vm.dateFormat)+" ")],1)])],1),(_vm.loading)?_c('div',{style:({ textAlign: 'center' })},[_c('a-spin',{attrs:{"tip":_vm.labels.loadingText,"spinning":_vm.loading}})],1):(!_vm.loading && !!_vm.raffles.length)?_c('a-row',{attrs:{"type":"flex","justify":"center","gutter":[24, 24]}},_vm._l((_vm.raffles),function(raffle,key){return _c('a-col',{key:key,attrs:{"span":18}},[_c('a-card',{staticClass:"result-list-item"},[_c('a-row',{attrs:{"type":"flex","justify":"center"}},[_c('a-col',{staticClass:"result-list-item-details",attrs:{"lg":18,"md":16,"sm":24}},[_c('span',{staticClass:"result-list-item-details-logo"},[(raffle.lottery.logo)?_c('img',{attrs:{"src":raffle.lottery.logo,"alt":raffle.lottery.name},on:{"error":function($event){raffle.lottery.logo = null}}}):(raffle.lottery.abbreviated)?_c('a-avatar',{style:({
                  backgroundColor: '#131340 ',
                  verticalAlign: 'middle',
                }),attrs:{"shape":"square","size":"large"}},[_vm._v(" "+_vm._s(_vm._f("ucword")(raffle.lottery.abbreviated))+" ")]):_vm._e()],1),_c('span',{staticClass:"result-list-item-details-name"},[(!_vm.showDescription)?_c('router-link',{attrs:{"to":{
                  name: 'lottery-results',
                  params: { lottery: raffle.lottery.slug },
                }}},[_c('h2',[_vm._v(_vm._s(_vm.title(raffle.lottery.name)))])]):_c('h2',[_vm._v(_vm._s(_vm.title(raffle.lottery.name)))]),(_vm.description(raffle.lottery.name))?_c('p',[_vm._v(" "+_vm._s(_vm.labels.raffle)+" "+_vm._s(_vm._f("ucfirst")(_vm.description(raffle.lottery.name)))+" ")]):_vm._e()],1),_c('span',{staticClass:"result-list-item-details-date"},[_c('label',[_vm._v(_vm._s(_vm.labels.raffleDateLabel))]),_c('p',[_vm._v(_vm._s(_vm.handleDateFormat(raffle.date)))]),_c('p',[_c('a-icon',{style:({ marginRight: '3px' }),attrs:{"type":"clock-circle","title":_vm.labels.lotteryCloseTimeLabel}}),_vm._v(" "+_vm._s(_vm.lotteryEndTime(raffle.lottery, raffle.date))+" ")],1)])]),_c('a-col',{staticClass:"result-list-item-numbers",attrs:{"lg":6,"md":8,"sm":24}},[_c('span',[_vm._v(_vm._s(raffle.first_prize))]),_c('span',[_vm._v(_vm._s(raffle.second_prize))]),_c('span',[_vm._v(_vm._s(raffle.third_prize))])])],1)],1)],1)}),1):_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('h2',{staticClass:"not-results"},[_vm._v(_vm._s(_vm.labels.notResults))])])],1),(_vm.showDescription && !!_vm.lotteryDescription)?_c('a-row',{attrs:{"type":"flex","justify":"center","gutter":[24, 24]}},[_c('a-col',{attrs:{"span":18}},[(_vm.showDescription && !!_vm.lotteryDescription)?_c('a-divider'):_vm._e(),_c('p',{staticClass:"description-paragraph",domProps:{"innerHTML":_vm._s(_vm.lotteryDescription)}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="result-list">
    <h2 class="result-list-title">
      <label for="date-picker"> {{ labels.resultsOf }}: </label>
      <a-date-picker
        v-model="date"
        :placeholder="dateFormat"
        inputReadOnly
        :disabledDate="disabledDate"
        :format="dateFormat"
        mode="date"
        :defaultValue="$moment()"
        :allowClear="false"
        htmlFor="date-picker"
        @change="onChange"
      >
        <span class="date-field">
          <font-awesome-icon :icon="['far', 'calendar-days']" class="anticon" />
          {{ date ? $moment(date).format(dateFormat) : dateFormat }}
        </span>
      </a-date-picker>
    </h2>
    <div v-if="loading" :style="{ textAlign: 'center' }">
      <a-spin :tip="labels.loadingText" :spinning="loading" />
    </div>
    <a-row
      v-else-if="!loading && !!raffles.length"
      type="flex"
      justify="center"
      :gutter="[24, 24]"
    >
      <a-col v-for="(raffle, key) in raffles" :key="key" :span="18">
        <a-card class="result-list-item">
          <a-row type="flex" justify="center">
            <a-col class="result-list-item-details" :lg="18" :md="16" :sm="24">
              <span class="result-list-item-details-logo">
                <img
                  v-if="raffle.lottery.logo"
                  :src="raffle.lottery.logo"
                  :alt="raffle.lottery.name"
                  @error="raffle.lottery.logo = null"
                />
                <a-avatar
                  v-else-if="raffle.lottery.abbreviated"
                  shape="square"
                  size="large"
                  :style="{
                    backgroundColor: '#131340 ',
                    verticalAlign: 'middle',
                  }"
                >
                  {{ raffle.lottery.abbreviated | ucword }}
                </a-avatar>
              </span>
              <span class="result-list-item-details-name">
                <router-link
                  v-if="!showDescription"
                  :to="{
                    name: 'lottery-results',
                    params: { lottery: raffle.lottery.slug },
                  }"
                >
                  <h2>{{ title(raffle.lottery.name) }}</h2>
                </router-link>
                <h2 v-else>{{ title(raffle.lottery.name) }}</h2>
                <p v-if="description(raffle.lottery.name)">
                  {{ labels.raffle }}
                  {{ description(raffle.lottery.name) | ucfirst }}
                </p>
              </span>
              <span class="result-list-item-details-date">
                <label>{{ labels.raffleDateLabel }}</label>
                <p>{{ handleDateFormat(raffle.date) }}</p>
                <p>
                  <a-icon
                    type="clock-circle"
                    :style="{ marginRight: '3px' }"
                    :title="labels.lotteryCloseTimeLabel"
                  />
                  {{ lotteryEndTime(raffle.lottery, raffle.date) }}
                </p>
              </span>
            </a-col>
            <a-col class="result-list-item-numbers" :lg="6" :md="8" :sm="24">
              <span>{{ raffle.first_prize }}</span>
              <span>{{ raffle.second_prize }}</span>
              <span>{{ raffle.third_prize }}</span>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    <a-row v-else>
      <a-col :span="24">
        <h2 class="not-results">{{ labels.notResults }}</h2>
      </a-col>
    </a-row>

    <!-- LOTTERY DESCRIPTION -->
    <a-row
      v-if="showDescription && !!lotteryDescription"
      type="flex"
      justify="center"
      :gutter="[24, 24]"
    >
      <a-col :span="18">
        <a-divider v-if="showDescription && !!lotteryDescription" />
        <p class="description-paragraph" v-html="lotteryDescription" />
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import _ from "lodash";
import labels from "@/utils/labels";
import { DATE_FORMAT } from "../utils/utils";
export default {
  name: "ResultList",
  props: {
    showDescription: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: this.$moment(),
      raffles: [],
      loading: false,
      labels: labels.results,
    };
  },
  created() {
    this.getRaffles(
      this.$moment().format(this.serverDateFormat),
      this.$route.params?.lottery
    );
  },
  computed: {
    disabledDate() {
      return (date) => {
        return date && date.valueOf() > Date.now();
      };
    },
    serverDateFormat() {
      return DATE_FORMAT.SERVER_DATE;
    },
    dateFormat() {
      return DATE_FORMAT.MOMENT_DATE;
    },
    lotteryDescription() {
      if (!this.raffles?.length) return null;
      return this.raffles[0]?.lottery?.description;
    },
  },
  methods: {
    ...mapActions("raffles", ["fetchClientRaffles"]),
    async getRaffles(date, lottery = null) {
      if (date) {
        this.loading = true;
        try {
          const response = await this.fetchClientRaffles({ date, lottery });
          this.raffles = response.data;
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    onChange(date) {
      this.date = date;
    },
    handleDateFormat(date) {
      return this.$moment(date, DATE_FORMAT.SERVER_DATE)
        .format("DD @ MMMM @ YYYY")
        ?.replace("@", "de")
        ?.replace("@", "del");
    },
    title(name) {
      let arr = name.split("-");
      return arr[0].trim();
    },
    description(name) {
      let arr = name.split("-");
      return arr.length > 1 ? arr[1].trim() : null;
    },
    lotteryEndTime(lottery, date) {
      const end_day = this.$moment(date)
        .locale("en")
        .format("dddd")
        .toLowerCase();
      return lottery[`${end_day}_end_time`] || "--:--";
    },
  },
  watch: {
    date(date, oldDate) {
      if (!_.isEqual(date, oldDate)) {
        this.getRaffles(
          date.format(this.serverDateFormat),
          this.$route.params?.lottery
        );
      }
    },
    "$route.params.lottery": {
      handler(lottery, oldLottery) {
        if (!_.isEqual(lottery, oldLottery)) {
          this.getRaffles(
            this.date ? this.$moment().format(this.serverDateFormat) : null,
            this.$route.params?.lottery
          );
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.result-list {
  &-title {
    font-family: "Exo 2", sans-serif;
    color: color(--white);
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
    word-break: break-word;
    margin-bottom: 1.8rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    label {
      margin-right: 10px;
    }
    .date-field {
      font-family: "Exo 2", sans-serif;
      color: color(--white);
      padding: 7px 40px 7px 15px;
      border-radius: 5px;
      border: 1px solid color(c-secondary);
      box-shadow: 0 1px 4px rgba(255, 255, 255, 0.05);
      .anticon {
        margin-right: 5px;
      }
    }
  }
  &-item {
    .ant-card-body {
      padding: 0;
    }
    background-color: color(c-secondary);
    border-color: color(c-secondary);
    padding: 0;
    border-radius: 0.45rem;
    box-shadow: 0 1px 0.125rem color(--white-light);
    &-numbers {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: rgb(19, 19, 64);
      background: linear-gradient(
        90deg,
        rgba(19, 19, 64, 1) 0%,
        rgba(32, 32, 78, 1) 25%,
        rgba(51, 255, 173, 1) 100%
      );
      border-radius: 0 0.45rem 0.45rem 0;
      height: 100px;
      @media screen and (max-width: $screen-mobile) {
        width: 100%;
        border-radius: 0 0 0.45rem 0.45rem;
      }
      span {
        font-family: "Exo 2", sans-serif;
        font-weight: 400;
        margin: 0;
        line-height: 1.3;
        font-size: 1rem;
        text-align: center;
        color: color(--white);
        font-size: 1.2rem;
        padding: 8px;
        background-color: color(c-primary);
        border-radius: 0.85rem;
        width: 39.81px;
      }
    }
    &-details {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 10px;
      padding: 0 10px 0px 30px;
      color: color(--white);
      @media screen and (max-width: $screen-mobile) {
        flex-direction: column;
      }
      &-logo {
        width: 235px;
        padding-right: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: $screen-mobile) {
          width: 130px;
          height: 90px;
          padding-right: 0;
        }
        img {
          width: 100%;
        }
      }
      &-name {
        width: 410px;
        @media screen and (max-width: $screen-mobile) {
          width: 100%;
          text-align: center;
        }
        p,
        h2 {
          margin-bottom: 0;
        }
        h2 {
          color: color(c-hover);
          font-size: 1.15rem;
          @media screen and (max-width: $screen-mobile) {
            font-size: 1.5rem;
          }
        }
      }
      &-date {
        text-align: right;
        width: 100%;
        @media screen and (max-width: $screen-mobile) {
          text-align: center;
          margin-bottom: 10px;
        }
        label {
          color: color(c-hover);
        }
        p {
          margin-bottom: 0;
        }
      }
    }
  }
  .not-results {
    text-align: center;
    font-size: 0.9rem;
    font-family: "Exo 2", sans-serif;
    color: color(c-hover);
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
    word-break: break-word;
    margin-bottom: 1.8rem;
  }
  .description-paragraph {
    width: 100%;
    * {
      color: color(--white) !important;
    }
  }
}
</style>

<template>
  <div class="join-our-mail-list">
    <a-row type="flex" justify="center" align="middle" :gutter="[16, 16]">
      <a-col :lg="20">
        <a-card class="join-our-mail-list__card" :bordered="false">
          <a-row
            type="flex"
            justify="space-between"
            :style="{ alignItems: 'center' }"
            :gutter="[16, 16]"
          >
            <a-col :md="10">
              <h2 class="title">{{ labels.title }}</h2>
            </a-col>
            <a-col :md="14">
              <ValidationObserver ref="observer">
                <a-form
                  :label-width="80"
                  slot-scope="{ validate }"
                  @submit.prevent="validate().then(handleSubmit)"
                >
                  <a-row
                    type="flex"
                    justify="center"
                    :style="{ alignItems: 'center' }"
                  >
                    <a-col :md="18">
                      <Input
                        v-model="form.email"
                        rules="required|email"
                        vid="email"
                        :placeholder="labels.form.email.placeholder"
                        size="large"
                        :allowClear="true"
                        :label="labels.form.email.label"
                        :showLabel="false"
                      />
                    </a-col>
                    <a-col :md="6">
                      <!-- Subscribe button -->
                      <a-form-item style="text-align: right">
                        <a-button
                          type="default"
                          html-type="submit"
                          :loading="submitting"
                          :disabled="submitting"
                          size="large"
                          class="join-our-mail-list__button"
                        >
                          <span>{{ labels.form.submit }}</span>
                        </a-button>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form>
              </ValidationObserver>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import { mapActions } from "vuex";
import Input from "@/components/core/VeeValidateForm/Input";
import labels from "@/utils/labels";
import _ from "lodash";
const initialState = {
  email: "",
};
export default {
  name: "JoinOurMailList",
  components: {
    ValidationObserver,
    Input,
  },
  data() {
    return {
      labels: labels.joinOurMailList,
      submitting: false,
      form: _.cloneDeep(initialState),
    };
  },
  methods: {
    ...mapActions("clientAuth", ["handleJoinMailList"]),
    async handleSubmit() {
      try {
        this.submitting = true;
        await this.handleJoinMailList(this.form);
        this.$success({
          title: this.labels.form.success,
          centered: true,
          // JSX support
          content: <p>{this.labels.form.successMessage}</p>,
          onOk: () => {
            this.form = _.cloneDeep(initialState);
            this.$refs.observer.reset();
          },
        });
      } catch (error) {
        this.submitting = false;
        this.$notification["error"]({
          message: `${error?.message || this.labels.error}`,
          description: error?.additional_errors?.length
            ? error.additional_errors
            : this.labels.errorMessage,
        });
        this.$log.error(error);
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.join-our-mail-list {
  &__card {
    padding: 26px;
    box-shadow: 0 0 10px color(c-hover-light);
    background-color: color(c-secondary);
    border-radius: 10px;
    .title {
      font-family: "Exo 2", sans-serif;
      color: #fff;
      font-weight: 400;
      margin: 0;
      line-height: 1.3;
      word-break: break-word;
      font-size: 2rem;
    }
  }
  &__button {
    background-color: color(c-hover);
    border-color: color(c-hover);
    color: color(c-primary);
    border-radius: 50px;
    height: 60px;
    &:hover,
    &:focus,
    &:active {
      background-color: color(c-hover);
      border-color: color(c-primary);
      color: color(c-primary);
    }
  }
  .has-error .ant-input-affix-wrapper .ant-input,
  .has-error .ant-input-affix-wrapper .ant-input:hover {
    background-color: transparent;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid color(--red);
  }
  .ant-input-clear-icon {
    color: color(c-hover);
  }
  .ant-input {
    border-radius: 0;
    background-color: transparent;
    border: none;
    color: color(--white);
    &:focus,
    &:focus-visible,
    &:hover,
    &:active {
      border: none;
      box-shadow: none;
      border-bottom: 1px solid color(c-hover);
      background-color: transparent;
    }
    border-bottom: 1px solid color(c-hover);
  }
}
</style>
